body {
  margin: 0;
  overflow: hidden;
}

dl {
  margin-block: unset;
}

details summary {
  cursor: pointer;
}

fieldset,
legend {
  margin: 0;
  padding: 0;
  border: 0;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

@supports (height: 100dvh) {
  #root {
    height: 100dvh;
  }
}
